<template>
  <article v-if="product" class=" position-relative  slider-hover w-100" :style="lowOpacity ? 'opacity: 0.7' : ''">
    <!--    <template v-if="product && product.varieties != '' && sortVarieties[0] && sortVarieties[0].final_price.discount_type && product.status == 'available' ">-->
    <!--      <DiscountTag :variety="sortVarieties[0]" />-->
    <!--    </template>-->

    <router-link
      :to="
        product
          ? {
              name: 'product.detail',
              params: { id: product.id, slug: product.slug },
            }
          : ''
      "
      class="text-center"
    >
      <div
        class="recommended-products-img text-center"
        :style="
          !product
            ? 'background-image: radial-gradient(#f4f4f4,#f4f4f4,#f4f4f4)'
            : ''
        "
      >
      <figure class="overflow-hidden figure-products">

        <img
          loading="lazy"
          v-if="product && mainImage"
          class="image-products"
          :src="getImage(mainImage, 'lg')"
          :alt="product.title"
        />
        <img
          loading="lazy"
          v-else
          class="skeleton-image"
          :alt="product ? product.title : ''"
        />
      </figure>

        <div v-if="product" class="show-by-hover recommended-title-hover">
          <div
            class="product-rate d-flex align-items-center justify-content-center"
          >
            <b-form-rating :value="product.rate" disabled size="sm" />
            <!-- <svg class="mr-n1" width="15" height="15" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.7141 3.44083L15.0301 8.09983C15.1461 8.33483 15.3701 8.49883 15.6301 8.53683L20.8151 9.28583C21.0251 9.31383 21.2141 9.42383 21.3431 9.59183C21.5851 9.90683 21.5481 10.3528 21.2581 10.6238L17.5001 14.2578C17.3091 14.4378 17.2241 14.7018 17.2741 14.9588L18.1741 20.0868C18.2371 20.5118 17.9471 20.9098 17.5221 20.9788C17.3461 21.0058 17.1661 20.9778 17.0061 20.8988L12.3881 18.4778C12.1561 18.3518 11.8781 18.3518 11.6461 18.4778L6.99413 20.9118C6.60513 21.1098 6.12913 20.9628 5.91713 20.5818C5.83613 20.4278 5.80813 20.2528 5.83613 20.0818L6.73613 14.9538C6.78113 14.6978 6.69613 14.4348 6.51013 14.2538L2.73213 10.6208C2.42413 10.3148 2.42213 9.81683 2.72913 9.50883C2.73013 9.50783 2.73113 9.50583 2.73213 9.50483C2.85913 9.38983 3.01513 9.31283 3.18413 9.28283L8.37013 8.53383C8.62913 8.49283 8.85213 8.33083 8.97013 8.09583L11.2841 3.44083C11.3771 3.25183 11.5421 3.10683 11.7421 3.04083C11.9431 2.97383 12.1631 2.98983 12.3521 3.08483C12.5071 3.16183 12.6341 3.28683 12.7141 3.44083Z"
                fill="#a5a5a5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg class="mr-n1" width="15" height="15" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.7141 3.44083L15.0301 8.09983C15.1461 8.33483 15.3701 8.49883 15.6301 8.53683L20.8151 9.28583C21.0251 9.31383 21.2141 9.42383 21.3431 9.59183C21.5851 9.90683 21.5481 10.3528 21.2581 10.6238L17.5001 14.2578C17.3091 14.4378 17.2241 14.7018 17.2741 14.9588L18.1741 20.0868C18.2371 20.5118 17.9471 20.9098 17.5221 20.9788C17.3461 21.0058 17.1661 20.9778 17.0061 20.8988L12.3881 18.4778C12.1561 18.3518 11.8781 18.3518 11.6461 18.4778L6.99413 20.9118C6.60513 21.1098 6.12913 20.9628 5.91713 20.5818C5.83613 20.4278 5.80813 20.2528 5.83613 20.0818L6.73613 14.9538C6.78113 14.6978 6.69613 14.4348 6.51013 14.2538L2.73213 10.6208C2.42413 10.3148 2.42213 9.81683 2.72913 9.50883C2.73013 9.50783 2.73113 9.50583 2.73213 9.50483C2.85913 9.38983 3.01513 9.31283 3.18413 9.28283L8.37013 8.53383C8.62913 8.49283 8.85213 8.33083 8.97013 8.09583L11.2841 3.44083C11.3771 3.25183 11.5421 3.10683 11.7421 3.04083C11.9431 2.97383 12.1631 2.98983 12.3521 3.08483C12.5071 3.16183 12.6341 3.28683 12.7141 3.44083Z"
                fill="#a5a5a5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg class="mr-n1" width="15" height="15" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.7141 3.44083L15.0301 8.09983C15.1461 8.33483 15.3701 8.49883 15.6301 8.53683L20.8151 9.28583C21.0251 9.31383 21.2141 9.42383 21.3431 9.59183C21.5851 9.90683 21.5481 10.3528 21.2581 10.6238L17.5001 14.2578C17.3091 14.4378 17.2241 14.7018 17.2741 14.9588L18.1741 20.0868C18.2371 20.5118 17.9471 20.9098 17.5221 20.9788C17.3461 21.0058 17.1661 20.9778 17.0061 20.8988L12.3881 18.4778C12.1561 18.3518 11.8781 18.3518 11.6461 18.4778L6.99413 20.9118C6.60513 21.1098 6.12913 20.9628 5.91713 20.5818C5.83613 20.4278 5.80813 20.2528 5.83613 20.0818L6.73613 14.9538C6.78113 14.6978 6.69613 14.4348 6.51013 14.2538L2.73213 10.6208C2.42413 10.3148 2.42213 9.81683 2.72913 9.50883C2.73013 9.50783 2.73113 9.50583 2.73213 9.50483C2.85913 9.38983 3.01513 9.31283 3.18413 9.28283L8.37013 8.53383C8.62913 8.49283 8.85213 8.33083 8.97013 8.09583L11.2841 3.44083C11.3771 3.25183 11.5421 3.10683 11.7421 3.04083C11.9431 2.97383 12.1631 2.98983 12.3521 3.08483C12.5071 3.16183 12.6341 3.28683 12.7141 3.44083Z"
                fill="#f58d0f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg class="mr-n1" width="15" height="15" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.7141 3.44083L15.0301 8.09983C15.1461 8.33483 15.3701 8.49883 15.6301 8.53683L20.8151 9.28583C21.0251 9.31383 21.2141 9.42383 21.3431 9.59183C21.5851 9.90683 21.5481 10.3528 21.2581 10.6238L17.5001 14.2578C17.3091 14.4378 17.2241 14.7018 17.2741 14.9588L18.1741 20.0868C18.2371 20.5118 17.9471 20.9098 17.5221 20.9788C17.3461 21.0058 17.1661 20.9778 17.0061 20.8988L12.3881 18.4778C12.1561 18.3518 11.8781 18.3518 11.6461 18.4778L6.99413 20.9118C6.60513 21.1098 6.12913 20.9628 5.91713 20.5818C5.83613 20.4278 5.80813 20.2528 5.83613 20.0818L6.73613 14.9538C6.78113 14.6978 6.69613 14.4348 6.51013 14.2538L2.73213 10.6208C2.42413 10.3148 2.42213 9.81683 2.72913 9.50883C2.73013 9.50783 2.73113 9.50583 2.73213 9.50483C2.85913 9.38983 3.01513 9.31283 3.18413 9.28283L8.37013 8.53383C8.62913 8.49283 8.85213 8.33083 8.97013 8.09583L11.2841 3.44083C11.3771 3.25183 11.5421 3.10683 11.7421 3.04083C11.9431 2.97383 12.1631 2.98983 12.3521 3.08483C12.5071 3.16183 12.6341 3.28683 12.7141 3.44083Z"
                fill="#f58d0f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg class="mr-n1" width="15" height="15" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.7141 3.44083L15.0301 8.09983C15.1461 8.33483 15.3701 8.49883 15.6301 8.53683L20.8151 9.28583C21.0251 9.31383 21.2141 9.42383 21.3431 9.59183C21.5851 9.90683 21.5481 10.3528 21.2581 10.6238L17.5001 14.2578C17.3091 14.4378 17.2241 14.7018 17.2741 14.9588L18.1741 20.0868C18.2371 20.5118 17.9471 20.9098 17.5221 20.9788C17.3461 21.0058 17.1661 20.9778 17.0061 20.8988L12.3881 18.4778C12.1561 18.3518 11.8781 18.3518 11.6461 18.4778L6.99413 20.9118C6.60513 21.1098 6.12913 20.9628 5.91713 20.5818C5.83613 20.4278 5.80813 20.2528 5.83613 20.0818L6.73613 14.9538C6.78113 14.6978 6.69613 14.4348 6.51013 14.2538L2.73213 10.6208C2.42413 10.3148 2.42213 9.81683 2.72913 9.50883C2.73013 9.50783 2.73113 9.50583 2.73213 9.50483C2.85913 9.38983 3.01513 9.31283 3.18413 9.28283L8.37013 8.53383C8.62913 8.49283 8.85213 8.33083 8.97013 8.09583L11.2841 3.44083C11.3771 3.25183 11.5421 3.10683 11.7421 3.04083C11.9431 2.97383 12.1631 2.98983 12.3521 3.08483C12.5071 3.16183 12.6341 3.28683 12.7141 3.44083Z"
                fill="#f58d0f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg> -->
            <span class="fontsize11 text-color-999"
              >(<span>{{ product.rate }}</span
              >)</span
            >
          </div>
          <h6 class="text-color-444 weight-bold fontsize-medium py-2 mb-0">
            {{ product.title }}
          </h6>
          <div v-if="product.status == 'available'" class="d-flex justify-content-center align-items-center" style="gap: 4px;">
            <span class="text-color-themeRed fontsize-medium ">{{
              mainPrice | price
            }}</span>
            <span class="text-color-666 fontsize14 pr-1">تومان</span>
            <span v-if="discountAmount != 0" class="pr-1 d-flex align-items-center" style="order: -1;">
              
              <span class="line-through text-color-999 fontsize-small">{{
                (mainPrice + discountAmount) | price
              }}</span>
              <svg style="transform: rotateY(180deg) translateY(-2px);"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-chevron-double-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#aaa"
                  fill-rule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                />
                <path
                  fill="#aaa"
                  fill-rule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </span>
          </div>
          <div v-else>
            <span
              v-if="product.status == 'out_of_stock'"
              class="text-color-themeRed fontsize14"
            >
              ناموجود
            </span>
            <span
              v-if="product.status == 'soon'"
              class="text-color-themeRed fontsize14"
            >
              به زودی
            </span>
          </div>
          <div class="recommended-products-cart my-3 text-center show-by-hover">
            <router-link
              :to="
                product != null
                  ? { name: 'product.detail', params: { id: product.id } }
                  : ''
              "
              v-if="product != null"
              type="button"
              class="bg-color-theme fontsize-mini py-2 px-3 weight-bold text-white"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.7729 9.30504V6.27304C15.7729 4.18904 14.0839 2.50004 12.0009 2.50004C9.91691 2.49104 8.21991 4.17204 8.21091 6.25604V6.27304V9.30504"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.7422 21.0003H7.25778C4.90569 21.0003 3 19.0953 3 16.7453V11.2293C3 8.87933 4.90569 6.97433 7.25778 6.97433H16.7422C19.0943 6.97433 21 8.87933 21 11.2293V16.7453C21 19.0953 19.0943 21.0003 16.7422 21.0003Z"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              افزودن به سبد
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="product" class="text-center position-relative">
        <h6
          class="hide-by-hover text-color-444 weight-bold fontsize-medium pt-2 pb-2 mb-0"
        >
          {{ product.title.substr(0, 33) }}
          {{ product.title.length > 33 ? "..." : "" }}
        </h6>
        <div v-if="product.status == 'available'" class="hide-by-hover d-flex justify-content-center align-items-center " style="gap: 4px;">
          <span class="text-color-themeRed fontsize-medium">
            {{ mainPrice | price }}
          </span>
          <span class="text-color-666 fontsize-small pr-1">تومان</span>
          <span v-if="discountAmount != 0" class="pr-1 d-flex align-items-center" style="order: -1;">
            
            <span class="line-through text-color-999 fontsize-small">{{
              (mainPrice + discountAmount) | price
            }}</span>
            <svg style="transform: rotateY(180deg) translateY(-2px);"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-double-right"
              viewBox="0 0 16 16"
            >
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
              />
              <path
                fill="#aaa"
                fill-rule="evenodd"
                d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </span>
        </div>
        <div v-else class="hide-by-hover">
          <span
            v-if="product.status == 'out_of_stock'"
            class="text-color-themeRed fontsize14"
          >
            ناموجود
          </span>
          <span
            v-if="product.status == 'soon'"
            class="text-color-themeRed fontsize14"
          >
            به زودی
          </span>
        </div>
      </div>
      <div v-else class="mt-3">
        <b-skeleton />
        <b-skeleton class="mt-2" />
      </div>
    </router-link>
    <!-- <button
      class="videoPlayer"
      v-if="!Array.isArray(product.video)"
      @click="showVideo(product.video.url)"
    >
      <img src="@/assets/images/player.gif" alt="video player" />
    </button> -->
    <!-- <div id="showVideoBox" v-if="showVideoBox">
      <button id="closeVideoModal" @click="showVideoBox = false">
        <svg
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="30px"
          height="30px"
        >
          <path
            d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
          />
        </svg>
      </button>
      <video class="videoContent" width="100%" height="100%" controls>
        <source :src="playerOptions" type="video/mp4" />
      </video>
    </div> -->
    <img class="vipLable" v-if="$route.path!='/'&&product.is_vip" src="@/assets/images/vip/vip.gif" alt="محصول وی آی پی" />
  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";

import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import DiscountTag from "./DiscountTag";
export default {
  name: "Product",
  components: { DiscountTag, BFormRating, BSkeleton },
  props: ["product", "lowOpacity"],
  data() {
    return {
      showVideoBox: false,
      playerOptions: null,
    };
  },
  methods: {
    getImage,
    showVideo(video) {
      this.playerOptions = video;
      this.showVideoBox = true;
    },
  },
  computed: {
    mainPrice() {
      return this.product ? this.product.price : null;
    },
    discountAmount() {
      return this.product ? this.product.major_discount_amount : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
  },
};
</script>

<style scoped>

@media screen and (max-width: 768px) {
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}

.skeleton-image {
  width: 308px;
  display: inline-block;
}
.image-products{
  transition: all .5s;
  width: 100%;
}
.slider-hover:hover .image-products{

  transform: scale(1.3);
}
</style>
<style>
/*#amazingSuggestBanner .slider-hover:hover#amazingSuggestBanner .image-products{
  transform: scale(1.5);
}*/
.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}
img.vipLable {
    position: absolute;
    z-index: 10000;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
}
</style>
